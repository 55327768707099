import React from 'react'
import { Button, Result } from 'antd';
import { useHistory, Link } from 'react-router-dom'

const Index = () => {
  const history = useHistory()
  const goHome = () => {
    history.go(-1)
  }
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="抱歉, 该页面不存在."
        extra={<Button type="primary" onClick={goHome}>返回</Button>}
      />
    </div>
  )
}

export default Index