/*
 * @Author: zhangwangwang 
 * @Date: 2021-06-04 14:31:22 
 * @Last Modified by: zhangwangwang
 * @Last Modified time: 2021-06-04 14:34:32
 */
const config = {}
export default (function () {
    return {
        ...config,
        ...require('./dev')
    }
})()