
export const mergeCells = (col_name, row, dataSource, compare_col_name = null) => {
    if (dataSource.length > 0) {
        const temp = {};
        let n = 0;
        if (col_name !== temp[col_name]) {
            temp[col_name] = row[col_name];

            dataSource.forEach((e) => {
                if (compare_col_name !== null) {
                    if (e[col_name] === temp[col_name] && e[compare_col_name] === row[compare_col_name]) {
                        n += 1;
                    }
                } else {
                    if (e[col_name] === temp[col_name]) {
                        n += 1;
                    }
                }
            })
        }
        return n
    } else {
        return []
    }
}
export default mergeCells

