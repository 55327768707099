
import React, { useEffect, useState } from 'react';
import { Modal, Image, Col, Input, Button, DatePicker, Descriptions, Form, message } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import styles from './index.less'
import util from '../../util/util';
import Menus from '@/component/menu'
import Record from '@/component/record'

const Index = () => {
    const [form] = Form.useForm();
    const [formb] = Form.useForm();
    const [extendInfo, setextendInfo] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenb, setIsModalOpenb] = useState(false);
    useEffect(() => {
        getUserInfo()
    }, [])

    const getUserInfo = (key) => {
        util.httpRequest({
            url: util.API + `/extend/extendInfo`,
            method: 'POST',

        }).then((res) => {
            if (res.code === 200) {
                setextendInfo(res.data)
            }
        }).catch((err) => {

        });

    }
    const handleOk = () => {
        form.submit()
        // setIsModalOpen(false);
    };
    const handleOkb = () => {
        formb.submit()
        // setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleCancelb = () => {
        setIsModalOpenb(false);
    };
    const onClickPass = () => {
        setIsModalOpen(true);
    }
    const onClickPassc = () => {
        setIsModalOpenb(true);
    }
    const onFinish = (values) => {
        if (values.newPwd !== values.reNewPwd) {
            message.warning('两次输入的密码不一致')
            return
        }
        util.httpRequest({
            url: util.API + `/extend/pwdUpdate`,
            method: 'POST',
            data: {
                orderPwd: values.orderPwd,
                newPwd: values.newPwd,
                reNewPwd: values.reNewPwd
            }
        }).then((res) => {
            if (res.code === 200) {
                setIsModalOpen(false);
                getUserInfo()
            } else {
                message.warning(res.message)
            }
        }).catch((err) => {

        });
    }
    const onFinishb = (values) => {
        util.httpRequest({
            url: util.API + `/extend/bankCardEdit`,
            method: 'POST',
            data: {
                extendCard: values.extendCard,
                extendCardName: values.extendCardName
            }
        }).then((res) => {
            if (res.code === 200) {
                setIsModalOpenb(false);
                getUserInfo()
            } else {
                message.warning(res.message)
            }
        }).catch((err) => {

        });

    }
    return (
        <div className={styles.home}>
            <Menus select={'pusercenter'} />
            <div className={styles.content}>

                <Descriptions bordered>
                    <Descriptions.Item label="推广团队ID">{extendInfo.extendId}</Descriptions.Item>
                    <Descriptions.Item label="团队名称">{extendInfo.extendName}</Descriptions.Item>
                    <Descriptions.Item label="团队级别">{extendInfo.typeChn}</Descriptions.Item>
                    {
                        extendInfo.shopName &&
                        <Descriptions.Item label="店铺名称">{extendInfo.shopName}</Descriptions.Item>
                    }
                    <Descriptions.Item label="联系电话">
                        {extendInfo.extendPhone}
                    </Descriptions.Item>
                    <Descriptions.Item label="联系地址">{extendInfo.address}</Descriptions.Item>
                    <Descriptions.Item label="联系人">{extendInfo.extendConnName}</Descriptions.Item>
                    <Descriptions.Item label="账号">{extendInfo.account}</Descriptions.Item>
                    <Descriptions.Item label="密码">{extendInfo.password}<Button onClick={onClickPass} style={{ marginLeft: '20px' }} size='small' type="primary">修改</Button></Descriptions.Item>
                    <Descriptions.Item label="分成比例">{extendInfo.divideNum}%</Descriptions.Item>
                    <Descriptions.Item label="银行卡">{extendInfo.extendCard}<Button onClick={onClickPassc} style={{ marginLeft: '20px' }} size='small' type="primary">添加/修改</Button></Descriptions.Item>
                    <Descriptions.Item label="持卡人姓名">{extendInfo.extendCardName}</Descriptions.Item>
                    <Descriptions.Item label="提交申请时间">{extendInfo.applyTime}</Descriptions.Item>
                    <Descriptions.Item label="审核时间">{extendInfo.approveTime}</Descriptions.Item>
                    {
                        extendInfo.shopCode &&
                        <Descriptions.Item label="店铺码">{extendInfo.shopCode}</Descriptions.Item>
                    }
                    <Descriptions.Item label="二维码/小程序码">
                        <Image
                            width={200}
                            src={extendInfo.qrCode}
                        />
                    </Descriptions.Item>
                </Descriptions>
            </div>
            <Modal
                title="修改密码"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                destroyOnClose
            >
                <Form
                    name="basic"
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >
                    <Form.Item
                        label="旧密码"
                        name="orderPwd"
                        rules={[
                            {
                                required: true,
                                message: '请输入旧密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="newPwd"
                        rules={[
                            {
                                required: true,
                                message: '请输入新密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="再次确认"
                        name="reNewPwd"
                        rules={[
                            {
                                required: true,
                                message: '请再次输入新密码',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="修改/添加银行卡"
                visible={isModalOpenb}
                onOk={handleOkb}
                onCancel={handleCancelb}
                centered
                destroyOnClose
            >
                <Form
                    name="basic"
                    onFinish={onFinishb}
                    form={formb}
                    autoComplete="off"
                >
                    <Form.Item
                        label="银行卡号"
                        name="extendCard"
                        rules={[
                            {
                                required: true,
                                message: '请输入银行卡号',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="持卡人姓名"
                        name="extendCardName"
                        rules={[
                            {
                                required: true,
                                message: '请输入持卡人姓名',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                </Form>
            </Modal>
            <Record />
        </div>
    )
}

export default Index