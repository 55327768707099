import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

function FrontendAuth(props) {
  const { routerConfig } = props;
  const { pathname } = useLocation();
  const isLogin = sessionStorage.getItem("userInfo");
  const isRole = sessionStorage.getItem("isRole");
  const targetRouterConfig = routerConfig.find(
    (item) => item.path === pathname
  );
  if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
    const { component, meta } = targetRouterConfig;
    // if (meta.title !== undefined) {
    //   document.title = meta.title;
    // }
    return <Route exact path={pathname} component={component} />;
  }
  if (isLogin && isRole === "1") {
    // 如果是登陆状态，想要跳转到登陆，重定向到主页
    if (pathname === "/login") {
      return <Redirect to="/home" />;
    } else {
      if (targetRouterConfig) {
        return (
          <Route path={pathname} component={targetRouterConfig.component} />
        );
      } else {
        // 如果路由不合法，重定向到 404 页面
        return <Redirect to="/error" />;
      }
    }
  } else if (isLogin && isRole === "2") {
    if (pathname === "/login") {
      return <Redirect to="/profit" />;
    } else {
      if (targetRouterConfig) {
        return (
          <Route path={pathname} component={targetRouterConfig.component} />
        );
      } else {
        // 如果路由不合法，重定向到 404 页面
        return <Redirect to="/error" />;
      }
    }
  } else {
    return <Redirect to="/login" />;
    // // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
    // if (targetRouterConfig && targetRouterConfig.auth) {
    //   return <Redirect to="/login" />;
    // } else {
    //   // 非登陆状态下，路由不合法时，重定向至 4 04
    //   return <Redirect to="/error" />;
    // }
  }
}

export default FrontendAuth;
