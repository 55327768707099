/*
 * @Author: zhangwangwang
 * @Date: 2021-06-04 12:22:36
 * @Last Modified by: zhangwangwang
 * @Last Modified time: 2022-07-13 14:57:56
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import "moment/locale/zh-cn";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
const container =  document.getElementById("root")
const root = createRoot(container)
root.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>,
)
serviceWorker.unregister();
