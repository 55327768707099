/*
 * @Author: zhangwangwang 
 * @Date: 2021-06-04 14:40:34 
 * @Last Modified by: zhangwangwang
 * @Last Modified time: 2024-06-11 15:57:54
 */
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Checkbox, Input, Button, message } from 'antd'
import styles from './index.less';
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import util from '../../util/util';
import Record from '@/component/record'

function Index(props) {
    const history = useHistory()
    useEffect(() => {

    }, [])
    const [userInfo, setuserInfo] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [remember, setremember] = useState(true);
    // const [isRole, setisRole] = useState(sessionStorage.getItem('isRole'));
    const [loading, setloading] = useState(false);
    const UserNanem = (e) => {
        setusername(e.target.value)
    }
    const Password = (e) => {
        setpassword(e.target.value)
    }
    // const rememberMe = (e) => {
    //     setremember(e.target.checked)
    // }
    const Login = (param) => {
        // if (remember) {
        //     localStorage.setItem('username', username)
        //     localStorage.setItem('password', password)
        //     localStorage.setItem('remember', remember)
        // }
        if (!param.username && !param.password) {
            message.error("输入账号密码")
            return
        }
        setloading(true)
        util.httpRequest({
            url: util.API + `/user/backstageLogin`,
            method: 'POST',
            data: {
                userAccount: param.username, //账号
                password: param.password//密码
            }
        }).then((res) => {
            if (res.code === 200) {
                sessionStorage.setItem("userInfo", res.data);
                sessionStorage.setItem("username", res.data.userName)
                sessionStorage.setItem("token", res.data.token)
                sessionStorage.setItem("isRole", res.data.userType.toString())
                window.location.reload()
            } else {
                setloading(false)
                message.error(res.message)
            }
        }).catch((err) => {

        });
        // sessionStorage.setItem("userInfo", username);
        // history.push('/home')



    }
    return (
        <div className={styles.main}>
            <Row>
                <Col span={16}>
                    <div className={styles.mainLeft}>
                        <div className={styles.images}>
                            <img
                                src={require("../../images/diver1.gif")}
                            ></img>
                        </div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.mainRight}>
                        <div className={styles.wordLogo}>
                            晓爱到家
                        </div>
                        <div className={styles.fromLogin}>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 4,
                                }}
                                autoComplete="off"
                                onFinish={Login}

                            >
                                <Form.Item
                                    // label="用户名"
                                    name="username"
                                >
                                    <Input prefix={<UserOutlined />} placeholder='用户名' onChange={UserNanem} />
                                </Form.Item>

                                <Form.Item
                                    // label="密码"
                                    name="password"
                                >
                                    <Input prefix={<LockOutlined />} placeholder='密码' type='password' onChange={Password} />
                                </Form.Item>

                                <Form.Item
                                    name="remember"
                                    valuePropName="checked"
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    {/* <Checkbox onChange={rememberMe}>记住我</Checkbox> */}
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button loading={loading} htmlType="submit" disabled={(username !== null && password !== null) ? false : true} type="primary">
                                        登录
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>

                </Col>
            </Row>
            <Record/>
        </div >
    )

}
export default Index