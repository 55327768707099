import React, { lazy } from "react";
import Login from "@/pages/login";
import Profit from "@/pages/profit";
import Pusercenter from "@/pages/pusercenter";
import Withdrawalrecordt from "@/pages/withdrawalrecordt";
import Promotionitem from "@/pages/promotionitem";
import Crew from "@/pages/crew";
import Error from "@/pages/error";
export const routerMapp = [
  {
    path: "/profit",
    name: "Profit",
    component: Profit,
    auth: true,
  },
  {
    path: "/pusercenter",
    name: "Pusercenter",
    component: Pusercenter,
    auth: true,
  },
  {
    path: "/login",
    name: "Login",
    title: "登录",
    component: Login,
  },
  {
    path: "/withdrawalrecordt",
    name: "Withdrawalrecordt",
    component: Withdrawalrecordt,
    auth: true,
  },
  {
    path: "/promotionitem",
    name: "Promotionitem",
    component: Promotionitem,
    auth: true,
  },
  {
    path: "/crew",
    name: "Crew",
    component: Crew,
    auth: true,
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    auth: true,
    title: "未找到",
    meta: {},
  },
];
