import { message } from "antd";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ showSpinner: false });
const axios = require("axios");
axios.defaults.withCredentials = false;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message.includes("timeout")) {
      window.location.href = "/errorTimeOut";
      return Promise.reject(error);
    }

    return Promise.reject(error.response); // 返回接口返回的错误信息
  }
);

// const logout = () => {
//     Modal.info({
//         title: '您的登录信息已过期，请重新登录，谢谢！',
//         okText: "重新登录",
//         onOk() {
//             localStorage.clear()
//             sessionStorage.clear()
//             window.location.href = "/"
//         },
//     });
// }

export const httpRequest = function (
  options = {
    url: "",
    method: "GET",
    data: {},
    headers: {},
    //该参数用户get请求方式
    params: {},
    onUploadProgress: (progress) => {},
    xhrFields: {
      withCredentials: false,
    },
  }
) {
  NProgress.start();

  options.headers = {
    DtdToken: sessionStorage.getItem("token"),
    ...options.headers,
  };

  return axios({
    url: options.url,
    method: options.method,
    data: options.data,
    onUploadProgress: options.onUploadProgress,
    params: options.params,
    headers: options.headers,
    xhrFields: options.xhrFields,
  })
    .then((res) => {
      NProgress.done();
      return res.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const httpRequestSync = async function (...param) {
  try {
    let res = await httpRequest(...param);
    return [res, null];
  } catch (e) {
    return [null, e];
  }
};
export default httpRequest;
