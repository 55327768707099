import React, { useEffect, useState } from 'react';
import { Tabs, Modal, Form, Row, Col, Image, Button, DatePicker, Space, Table, message, Select, Popconfirm } from 'antd';
import Menus from '@/component/menu'
import util from '../../util/util';
import styles from './index.less'
import Record from '@/component/record'
const Index = () => {


    const [datajs, setDatajs] = useState([]);
    const [keyjs, setKeyjs] = useState(0);
    const [loading, setloading] = useState(false);

    const columnsjs = [
        {
            title: '用户ID',
            dataIndex: 'userId',
            key: 'userId',
            align: 'center',
            width: 100,
        },
        {
            title: '反馈内容',
            dataIndex: 'problemContent',
            key: 'problemContent',
            align: 'center',
            width: 200,
        },
        {
            title: '反馈图片',
            dataIndex: 'imageDetail',
            key: 'imageDetail',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <>
                    {
                        record.images.map((item) => {
                            return <Image
                                width={200}
                                height={50}
                                src={item}
                            />
                        })
                    }

                </>
            ),
        },

        {
            title: '申请时间',
            dataIndex: 'insertTime',
            key: 'insertTime',
            align: 'center',
        },
        {
            title: '处理时间',
            dataIndex: 'approveTime',
            key: 'approveTime',
            align: 'center',
        },
        {
            title: '是否处理',
            dataIndex: 'cardNum',
            key: 'cardNum',
            align: 'center',
            render: (_, record) =>
                record.workOut === 0 ? '否' : '是',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (_, record) => {
                if (record.workOut === 0) {
                    return <Popconfirm
                        title={'确认处理'}
                        onConfirm={() => confirm(record.id)}
                        okText="是"
                        cancelText="否"
                    >
                        <span style={{ color: '#cfae9f', cursor: 'pointer' }}>处理</span>
                    </Popconfirm>


                }
                // else {
                //     return <span style={{ color: '#CCC', cursor: 'pointer' }}>已打款</span>
                // }
            }
        },
    ];

    // useEffect(() => {
    //     getData()
    // }, [key])
    useEffect(() => {
        getDatajs()
    }, [keyjs])
    const confirm = (id) => {
        util.httpRequest({
            url: util.API + `/admin/artFeedBackApprove`,
            method: 'POST',
            data: {
                id
            }
        }).then((res) => {
            if (res.code === 200) {
                message.success('操作成功')
                getDatajs()
            }
        }).catch((err) => {

        });
    }

    const getDatajs = () => {
        setloading(true)
        util.httpRequest({
            url: util.API + `/admin/artFeedBackList`,
            method: 'POST',
            data: {
                status: keyjs
            }
        }).then((res) => {
            if (res.code === 200) {

                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].imageDetail) {
                        res.data[i].images = res.data[i].imageDetail.split(',')
                    } else {
                        res.data[i].images = []
                    }
                }
                console.log("res", res.data)
                setDatajs(res.data)

                setloading(false)
            }
        }).catch((err) => {

        });
    }



    const onChangeSelectf = (value) => {
        setKeyjs(value)
    }
    const onChange = (key) => {
        if (key == 2) {
            getDatajs()
        }
    };
    return (
        <div className={styles.home}>
            <Menus select={'feedback'} />
            <div className={styles.content}>
                <Tabs onChange={onChange}>
                    {/* <Tabs.TabPane tab="用户端" key={1}>
                        <div className={styles.select}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="status"
                                        label="处理状态"
                                    >
                                        <Select defaultValue={0} onChange={onChangeSelect}>
                                            <Select.Option value={0}>未处理</Select.Option>
                                            <Select.Option value={1}>已处理</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            dataSource={data}
                            columns={columns}
                            pagination={false}
                            loading={loading}
                            rowKey={record => record.id}
                        />
                    </Tabs.TabPane> */}
                    <Tabs.TabPane tab="理疗师端" key={2}>
                        <div className={styles.select}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="status"
                                        label="处理状态"
                                    >
                                        <Select defaultValue={0} onChange={onChangeSelectf}>
                                            <Select.Option value={0}>未处理</Select.Option>
                                            <Select.Option value={1}>已处理</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            dataSource={datajs}
                            columns={columnsjs}
                            pagination={false}
                            loading={loading}
                            rowKey={record => record.id}
                        />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane tab="店铺端" key={3}>
                        <div className={styles.select}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="status"
                                        label="处理状态"
                                    >
                                        <Select defaultValue={0} onChange={onChangeSelectt}>
                                            <Select.Option value={0}>未处理</Select.Option>
                                            <Select.Option value={1}>已处理</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            dataSource={datat}
                            columns={columnst}
                            pagination={false}
                            loading={loading}
                            rowKey={record => record.id}
                        />
                    </Tabs.TabPane> */}
                    {/* <Tabs.TabPane tab="推广" key={4}>
                    <div className={styles.select}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="status"
                                        label="处理状态"
                                    >
                                        <Select defaultValue={0} onChange={onChangeSelectt}>
                                            <Select.Option value={0}>未处理</Select.Option>
                                            <Select.Option value={1}>已处理</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            dataSource={datat}
                            columns={columnst}
                            pagination={false}
                            loading={loading}
                            rowKey={record => record.id}
                        />

                    </Tabs.TabPane> */}
                </Tabs>

            </div>
            <Record/>
        </div>
    )
}

export default Index