//技师管理
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Modal, Button, DatePicker, Space, Table, message, Select, Image, InputNumber } from 'antd';
import { UsergroupAddOutlined, AppstoreOutlined, SettingOutlined, FormOutlined } from '@ant-design/icons';
import styles from './index.less'
import util from '../../util/util';
import Menus from '@/component/menu'
import moment from "moment";
import Record from '@/component/record'
const { RangePicker } = DatePicker;
const { Option } = Select;
const Index = () => {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [extendId, setExtendId] = useState('');
    useEffect(() => {
        getData(2)
    }, [])
    const getData = (enable) => {
        setTableLoading(true)
        util.httpRequest({
            url: util.API + `/extend/backExtendList`,
            method: 'POST',
            data: {
                enable
            }
        }).then((res) => {
            if (res.code === 200) {
                setDataSource(res.data)
            } else {
                message.error(res.message)
            }
            setTableLoading(false)
        }).catch((err) => {

        });
    }
    const onReset = () => {
        form.resetFields();
    };
    const onFinish = (values) => {
        getData(values.status)
    }
    const onChangeStatus = (value) => {
        getData(value)
    }
    const approvepro1 = (param) => {
        Modal.confirm({
            title: "确认审核通过?",
            cancelText: "取消",
            okText: "确定",
            centered: 'center',
            onCancel: () => {

            },
            onOk: () => {
                approvepro(param)
            },
        });
    }
    const approvepro = (param) => {
        util.httpRequest({
            url: util.API + `/extend/approve?extendId=${param.extendId}`,
            method: 'POST',
        }).then((res) => {
            if (res.code === 200) {
                message.success(res.message)
                getData(form.getFieldValue('status'))
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const handleOk = () => {
        form1.submit()
        // setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const setProportion = (obj) => {
        form1.setFieldsValue({
            scale: obj.divideNum,
        })
        setExtendId(obj.extendId)
        setIsModalOpen(true);
    }
    const onFinish1 = (values) => {
        util.httpRequest({
            url: util.API + `/extend/divideSetPlat`,
            method: 'POST',
            data: {
                scale: values.scale,
                extendId,
            }
        }).then((res) => {
            if (res.code === 200) {
                setIsModalOpen(false);
                getData(form.getFieldValue('status'))
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    };
    const columns = [
        {
            title: '推广团队ID',
            dataIndex: 'extendId',
            key: 'extendId',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '所属平台',
            dataIndex: 'typeChn',
            key: 'typeChn',
            align: 'center',
        },
        {
            title: '团队名称',
            dataIndex: 'extendName',
            key: 'extendName',
            align: 'center',
        },
        {
            title: '分成比例',
            dataIndex: 'divideNum',
            key: 'divideNum',
            align: 'center',
            render: (_, record) => {
                if (record.divideNum) {
                    return (
                        <span>
                            {record.divideNum}%
                        </span>
                    )
                } else {
                    return '--'
                }
            }
        },


        {
            title: '联系人',
            dataIndex: 'extendConnName',
            key: 'extendConnName',
            align: 'center',
        },

        {
            title: '联系人电话',
            dataIndex: 'extendPhone',
            key: 'extendPhone',
            align: 'center',
        },
        {
            title: '二维码/小程序码',
            dataIndex: 'qrCode',
            key: 'qrCode',
            align: 'center',
            width: 200,
            render: (_, record) => {
                if (record.qrCode) {
                    return (
                        <Image
                            width={180}
                            height={50}
                            src={record.qrCode}
                        />
                    )
                } else {
                    return <span>未生成</span>
                }
            }
        },
        {
            title: '登录账号',
            dataIndex: 'account',
            key: 'account',
            align: 'center',
            width: 200,
        },
        {
            title: '密码',
            dataIndex: 'password',
            key: 'password',
            align: 'center',
            width: 100,
        },

        {
            title: '审核状态',
            dataIndex: 'enable',
            key: 'enable',
            align: 'center',
            render: (_, obj) => {
                if (obj.enable === 0) {
                    return '待审核'
                } else if (obj.enable === 1) {
                    return '审核通过'
                } else {
                    return '全部'
                }
            }
        },


        {
            title: '申请时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <span>
                    {moment(record.applyTime).format('YYYY-MM-DD HH:MM:SS')}
                </span>
            ),
        },

        {
            title: '审核时间',
            dataIndex: 'approveTime',
            key: 'approveTime',
            align: 'center',
            width: 200,
            render: (_, record) => {
                if (record.approveTime) {
                    return (
                        <span>
                            {moment(record.approveTime).format('YYYY-MM-DD HH:MM:SS')}
                        </span>
                    )
                } else {
                    return '--'
                }


            }


        },
        {
            title: '店铺码',
            dataIndex: 'shopCode',
            key: 'shopCode',
            align: 'center',
            width: 200,
        },
        {
            title: '店铺名称',
            dataIndex: 'shopName',
            key: 'shopName',
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            fixed: 'right',
            render: (_, obj) => {
                if (obj.enable === 0) {
                    return <Space>
                        <UsergroupAddOutlined title='审核' onClick={() => approvepro1(obj)} style={{ color: '#cfae9f', fontSize: '1.5rem' }} />
                        {obj.type === 1 && <FormOutlined title='分成比例' onClick={() => setProportion(obj)} style={{ color: '#cfae9f', fontSize: '1.5rem' }} />}
                    </Space>

                } else if (obj.enable === 1) {
                    return <Space>
                        <UsergroupAddOutlined title='审核' style={{ color: '#ccc', fontSize: '1.5rem', }} />
                        {obj.type === 1 && <FormOutlined title='分成比例' onClick={() => setProportion(obj)} style={{ color: '#cfae9f', fontSize: '1.5rem' }} />}
                    </Space>
                }

            }
        },
    ];
    return (
        <div className={styles.home}>
            <Menus select={'promotion'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{
                            status: 2,
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="status"
                                    label="审核状态"
                                >
                                    <Select onChange={onChangeStatus}>
                                        <Option value={0}>待审核</Option>
                                        <Option value={1}>审核通过</Option>
                                        <Option value={2}>全部</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                {/* <Form.Item
                                    name="status"
                                    label="订单状态"
                                >
                                    <Input />
                                </Form.Item> */}
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item
                                    name="datte"
                                    label="订单时间"
                                >
                                    <RangePicker />
                                </Form.Item>
                            </Col> */}
                            {/* <Col span={6} style={{ textAlign: 'end' }}>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Space size={24}>
                                        <Button type="primary" htmlType="submit">
                                            查询
                                        </Button>
                                        <Button htmlType="button" onClick={onReset}>
                                            重置
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col> */}
                        </Row>

                    </Form>
                </div>
                <div className={styles.table}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        loading={tableLoading}
                        rowKey={record => record.artificerId}
                        scroll={{
                            x: 2500,
                            y: '45rem',
                        }}
                    />
                </div>
            </div>
            <Modal
                title="设置分成比例"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    onFinish={onFinish1}
                    form={form1}
                >
                    <Form.Item
                        label="分成比例"
                        name="scale"
                        rules={[
                            {
                                required: true,
                                message: '请输入分成比例',
                            },
                        ]}
                    >
                        <InputNumber min={0} max={50} style={{ width: "100%" }} addonAfter="%" />
                    </Form.Item>


                </Form>

            </Modal>
            <Record/>
        </div>
    )
}

export default Index