//统计
import React from 'react'
import Menus from '@/component/menu'
import styles from './index.less'
const Index = () => {
    return (
        <div className={styles.home}>
            <Menus select={'statistics'} />

        </div>
    )
}

export default Index