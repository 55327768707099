import React, { Fragment, Suspense, useState } from "react";
import "./App.less";
import { BrowserRouter as Router } from "react-router-dom";
import FrontendAuth from "./router/FrontendAuth";
import { routerMap } from "./router/routerMap";
import { routerMapp } from "./router/routerMapp";
import LoadingComponent from "./util/loadable";
const App = () => {
  let isRole = sessionStorage.getItem("isRole")
  return (
    <div className="App">
      <Router>
        <Suspense
          fallback={
            <div>
              <LoadingComponent />
            </div>
          }
        >
          <FrontendAuth
            routerConfig={isRole === "1" ? routerMap : routerMapp}
          />
        </Suspense>
      </Router>
    </div>
  );
};

export default App;
