//店铺
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Modal, Button, DatePicker, Space, Table, message, Select, Image } from 'antd';
import { UsergroupAddOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import styles from './index.less'
import util from '../../util/util';
import Menus from '@/component/menu'
import Record from '@/component/record'
const { RangePicker } = DatePicker;
const { Option } = Select;
const Index = () => {
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [key, setKey] = useState(0);
    useEffect(() => {
        getData(key)
    }, [key])
    const getData = (status) => {
        setTableLoading(true)
        util.httpRequest({
            url: util.API + `/admin/extendCasheRecord`,
            method: 'POST',
            data: {
                status
            }
        }).then((res) => {
            if (res.code === 200) {
                setDataSource(res.data)
            } else {
                message.error(res.message)
            }
            setTableLoading(false)
        }).catch((err) => {

        });
    }
    const onReset = () => {
        form.resetFields();
    };
    // const onFinish = (values) => {
    //     getData(values.status)
    // }
    const onChangeSelect = (value) => {
        setKey(value)
    }
    const approvepro1 = (param) => {
        Modal.confirm({
            title: "确认审核通过?",
            cancelText: "取消",
            okText: "确定",
            centered: 'center',
            onCancel: () => {

            },
            onOk: () => {
                approvepro(param)
            },
        });
    }
    const approvepro = (id) => {
        util.httpRequest({
            url: util.API + `/admin/examineExtend`,
            method: 'POST',
            data: {
                id
            }
        }).then((res) => {
            if (res.code === 200) {
                message.success(res.message)
                getData(form.getFieldValue('status'))
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const columns = [
        {
            title: '提现用户',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '提交申请时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            align: 'center',
            fixed: 'left',
        },
        {
            title: '审核时间',
            dataIndex: 'approveTime',
            key: 'approveTime',
            align: 'center',
        },
        {
            title: '银行卡号',
            dataIndex: 'cardNum',
            key: 'cardNum',
            align: 'center',
            width: 200,
        },
        // {
        //     title: '审核状态',
        //     dataIndex: 'enable',
        //     key: 'enable',
        //     align: 'center',
        //     render: (_, obj) => {
        //         if (obj.enable === 0) {
        //             return '待审核'
        //         } else if (obj.enable === 1) {
        //             return '审核通过'
        //         }
        //     }
        // },
        {
            title: '持卡人姓名',
            dataIndex: 'cardUserName',
            key: 'cardUserName',
            align: 'center',
            width: 200,
        },
        {
            title: '提现金额',
            dataIndex: 'casheMoney',
            key: 'casheMoney',
            align: 'center',
            width: 100,
        },

        {
            title: '可得到金额',
            dataIndex: 'availableMoney',
            key: 'availableMoney',
            align: 'center',
            width: 200,
        },
        {
            title: '手续费',
            dataIndex: 'casheCharge',
            key: 'casheCharge',
            align: 'center',
            width: 200,
        },

        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            fixed: 'right',
            render: (_, obj) => {
                if (obj.enable === 0) {
                    return <UsergroupAddOutlined title='审核' onClick={() => approvepro1(obj)} style={{ color: '#cfae9f', fontSize: '1.5rem' }} />
                } else if (obj.enable === 1) {
                    return <UsergroupAddOutlined title='审核' style={{ color: '#ccc', fontSize: '1.5rem' }} />
                }
            }
        },
    ];
    return (
        <div className={styles.home}>
            <Menus select={'withdrawalrecord'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        // onFinish={onFinish}
                        initialValues={{
                            status: 0,
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="status"
                                    label="审核状态"
                                >
                                    <Select onChange={onChangeSelect}>
                                        <Option value={0}>待审核</Option>
                                        <Option value={1}>审核通过</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                {/* <Form.Item
                                    name="status"
                                    label="订单状态"
                                >
                                    <Input />
                                </Form.Item> */}
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item
                                    name="datte"
                                    label="订单时间"
                                >
                                    <RangePicker />
                                </Form.Item>
                            </Col> */}
                            <Col span={6} style={{ textAlign: 'end' }}>
                                {/* <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Space size={24}>
                                        <Button type="primary" htmlType="submit">
                                            查询
                                        </Button>
                                        <Button htmlType="button" onClick={onReset}>
                                            重置
                                        </Button>
                                    </Space>
                                </Form.Item> */}
                            </Col>
                        </Row>

                    </Form>
                </div>
                <div className={styles.table}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        loading={tableLoading}
                        rowKey={record => record.artificerId}
                        scroll={{
                            x: 1600,
                            y: '43rem',
                        }}
                    />
                </div>
            </div>
            <Record />
        </div>
    )
}

export default Index