//订单管理
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Button, DatePicker, Space, Table, Select, Image, message } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import styles from './index.less'
import util from '../../util/util';
import Menus from '@/component/menu'
import moment from 'moment';
import Record from '@/component/record'
const { RangePicker } = DatePicker;
const Index = () => {
    const [form] = Form.useForm();
    const columns = [
        {
            title: '订单编号',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            align: 'center',
            width: 250,
        },
        {
            title: '推广ID',
            dataIndex: 'extendId',
            key: 'extendId',
            align: 'center',
            width: 150,
        },
        {
            title: '项目名称',
            dataIndex: 'serviceName',
            key: 'serviceName',
            align: 'center',
            width: 150,
        },
        {
            title: '项目金额',
            dataIndex: 'serviceMoney',
            key: 'serviceMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '下单地址',
            dataIndex: 'addressDetail',
            key: 'addressDetail',
            align: 'center',
            width: 200,
        },
        {
            title: '用户姓名',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
            width: 200,
        },
        {
            title: '用户手机号',
            dataIndex: 'userPhone',
            key: 'userPhone',
            align: 'center',
            width: 200,
        },
        {
            title: '订单城市',
            dataIndex: 'orderCity',
            key: 'orderCity',
            align: 'center',
            width: 200,
        },
        {
            title: '客户预约时间',
            dataIndex: 'appointTime',
            key: 'appointTime',
            align: 'center',
            width: 200,
        },
        {
            title: '订单时间',
            dataIndex: 'orderTime',
            key: 'orderTime',
            align: 'center',
            width: 200,
        },
        {
            title: '订单总金额',
            dataIndex: 'orderMoney',
            key: 'orderMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '打赏金额',
            dataIndex: 'rewardMoney',
            key: 'rewardMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '打车费',
            dataIndex: 'taxiMoney',
            key: 'taxiMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '优惠券',
            dataIndex: 'couponMoney',
            key: 'couponMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '理疗师利润',
            dataIndex: 'artificerMoney',
            key: 'artificerMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '店铺利润',
            dataIndex: 'agentMoney',
            key: 'agentMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '平台利润',
            dataIndex: 'orderProfit',
            key: 'orderProfit',
            align: 'center',
            width: 150,
        },
        {
            title: '推广利润',
            dataIndex: 'extendMoney',
            key: 'extendMoney',
            align: 'center',
            width: 150,
        },
        {
            title: '项目图片',
            dataIndex: 'serviceImage',
            key: 'serviceImage',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <Image
                    width={200}
                    height={50}
                    src={record.serviceImage}
                />
            ),
        },
        {
            title: '理疗师姓名',
            dataIndex: 'artificerName',
            key: 'artificerName',
            align: 'center',
            width: 150,
        },

        
        {
            title: '客户订单状态',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            align: 'center',
            width: 150,
        },
        {
            title: '理疗师订单状态',
            dataIndex: 'artificerStatus',
            key: 'artificerStatus',
            align: 'center',
            width: 150,
        },
        {
            title: '订单是否可修改',
            dataIndex: 'canEdit',
            key: 'canEdit',
            align: 'center',
            width: 150,
            render: (_, record) =>
                record.canEdit ? '是' : '否',
        },
    ];
    const [dataSource, setDataSource] = useState();
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [orderStatus, setOrderStatus] = useState('0');
    const [total, setTotal] = useState(0);
    const [loading, setloading] = useState(false);
    const [analyzeData, setAnalyzeData] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const [queryStartDate, setqueryStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [queryEndDate, setqueryEndDate] = useState(moment().format('YYYY-MM-DD'));
    useEffect(() => {
        form.submit()
    }, [orderStatus, queryStartDate, queryStartDate])
    useEffect(() => {
        getanalyze()
    }, [queryStartDate, queryStartDate])
    const onFinish = () => {
        setloading(true)
        setPageNum(1)
        setPageSize(10)
        util.httpRequest({
            url: util.API + `/order/orderList`,
            method: 'POST',
            data: {
                orderStatus,
                pageNum: 1,
                pageSize: 10,
                queryStartDate,
                queryEndDate,
            }
        }).then((res) => {
            if (res.code == 200) {
                setDataSource(res.data.list)
                setTotal(res.data.total)
                setloading(false)
            } else {
                setloading(false)
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const getData = (page, limit) => {
        setloading(true)
        setPageNum(page)
        setPageSize(limit)
        util.httpRequest({
            url: util.API + `/order/orderList`,
            method: 'POST',
            data: {
                orderStatus,
                pageNum: page,
                pageSize: limit,
                queryStartDate,
                queryEndDate,
            }
        }).then((res) => {
            if (res.code == 200) {
                setDataSource(res.data.list)
                setTotal(res.data.total)
                setloading(false)
            } else {
                message.error(res.message)
            }

        }).catch((err) => {

        });
    }
    const onChangeSelect = (value) => {
        setOrderStatus(value)
    }
    const getanalyze = () => {
        util.httpRequest({
            url: util.API + `/admin/analyze`,
            method: 'POST',
            data: {
                queryStartDate,
                queryEndDate,

            }
        }).then((res) => {
            setAnalyzeData(res.data)
        }).catch((err) => {

        });
    }
    const onChangeTime = (date) => {
        setqueryStartDate(moment(date[0]._d).format('YYYY-MM-DD'))
        setqueryEndDate(moment(date[1]._d).format('YYYY-MM-DD'))
    }
    return (
        <div className={styles.home}>
            <Menus select={'order'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        initialValues={{
                            'status': "0",
                            'datte': [moment(new Date(), dateFormat), moment(new Date(), dateFormat)],
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="status"
                                    label="订单状态"
                                >
                                    <Select onChange={onChangeSelect}>
                                        <Select.Option value="0">全部</Select.Option>
                                        <Select.Option value="1">待支付</Select.Option>
                                        <Select.Option value="2">待服务</Select.Option>
                                        <Select.Option value="3">服务中</Select.Option>
                                        <Select.Option value="4">已完成</Select.Option>
                                        <Select.Option value="5">退款/售后</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="datte"
                                    label="订单时间"
                                >
                                    <RangePicker onChange={onChangeTime} />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </div>
                <div className={styles.top}>

                    <Row gutter={16}>

                        <Col span={6}>
                            订单总数：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}> {analyzeData?.todayOrderNum}</span>

                        </Col>
                        <Col span={6}>
                            退款订单数：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}>
                                {analyzeData?.todayRefundOrderNum}
                            </span>
                        </Col>
                        <Col span={6}>
                            订单总金额：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}>
                                {analyzeData?.todayOrderMoney}
                            </span>
                        </Col>
                        <Col span={6}>
                            平台纯利润：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}>
                                {analyzeData?.todayPlatfromOrderProfitMoney}
                            </span>
                        </Col>
                    </Row>
                </div>
                <div className={styles.table}>
                    <Table
                        scroll={{ x: 3600, y: '38rem' }}
                        dataSource={dataSource}
                        columns={columns}
                        loading={loading}
                        pagination={{
                            pageSize: pageSize,
                            current: pageNum, // 默认显示第几页
                            total: total, // 共计几条
                            showTotal: total => `共计 ${total} 个`,
                            showSizeChanger: true, // 是否展示pageSize切换器                          
                            onChange: (page, limit) => {

                                getData(page, limit)
                            }
                        }}
                    />
                </div>
            </div>
            <Record/>
        </div>
    )
}

export default Index