
//服务项目
import React, { useEffect, useState } from 'react';
import Menus from '@/component/menu'
import { Popconfirm, Modal, Upload, Input, Button, DatePicker, Space, Table, message, Image, Form, Select, InputNumber, Switch } from 'antd';
import { EditOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import util from '../../util/util';
import moment from 'moment';
import styles from './index.less'
import Record from '@/component/record'
const { TextArea } = Input;
const Index = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [modelTitle, setModelTitle] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadImgeLoading, setUploadImgeLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [imageUrlDetail, setImageUrlDetail] = useState('');
    const [imageInfo, setImageInfo] = useState(null);
    const [imageInfoDetail, setImageInfoDetail] = useState(null);
    const [serviceId, setServiceId] = useState(1);
    useEffect(() => {
        getCommList()
    }, [])
    const getCommList = () => {
        setLoading(true)
        util.httpRequest({
            url: util.API + `/admin/serviceList`,
            method: 'GET',
            data: {}
        }).then((res) => {
            setLoading(false)
            if (res.code === 200) {
                setDataList(res.data)
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const addList = () => {
        setModelTitle('新增服务项目')
        setIsModalOpen(true)
    }
    const editComm = (record) => {
        setModelTitle('编辑服务项目')
        setServiceId(record.serviceId)
        setIsModalOpen(true)
        form.setFieldsValue({
            ...record, serviceImageDetail: record.ext1
        })
        setImageUrl(record.serviceImage)
        setImageUrlDetail(record.ext1)
    }
    const confirm = (record) => {
        let formData = new FormData();
        formData.append('serviceId', record.serviceId);
        formData.append('type', 2);
        util.httpRequest({
            url: util.API + `/admin/serviceEdit`,
            method: 'POST',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            if (res.code === 200) {
                message.success(res.message)
            } else {
                message.error(res.message)
            }
            getCommList()
        }).catch((err) => {

        });
    }
    const cancel = (record) => { }
    const handleOk = () => {
        form.submit()
    }
    const onFinish = (param) => {
        setUploadImgeLoading(true)
        let formData = new FormData();
        if (modelTitle === '编辑服务项目') {
            formData.append('serviceId', serviceId);
        }
        formData.append('serviceImage', param.serviceImage.file ? param.serviceImage.file.originFileObj : param.serviceImage);
        formData.append('serviceImageDetail', param.serviceImageDetail.file ? param.serviceImageDetail.file.originFileObj : param.serviceImageDetail);
        formData.append('type', modelTitle === '新增服务项目' ? 1 : 3);
        formData.append('serviceName', param.serviceName);
        formData.append('serviceDetail', param.serviceDetail);
        formData.append('serviceTime', param.serviceTime);
        formData.append('selected', param.selected);
        formData.append('serviceMoney', param.serviceMoney);
        util.httpRequest({
            url: util.API + `/admin/serviceEdit`,
            method: 'POST',
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then((res) => {
            if (res.code === 200) {
                getCommList()
                setImageUrl()
                setImageUrlDetail()
                setIsModalOpen(false)
                setUploadImgeLoading(false)
                message.success(res.message)
            } else {
                setUploadImgeLoading(false)
                message.error(res.message)
            }
        }).catch((err) => {

        });

    }
    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false)
        setImageUrl()
        setImageUrlDetail()
    }
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleChange = (info) => {
        setImageInfo(info.file.originFileObj)
        getBase64(info.file.originFileObj, (url) => {
            setImageUrl(url);
        });
    };
    const handleChangeDetail = (info) => {
        setImageInfoDetail(info.file.originFileObj)
        getBase64(info.file.originFileObj, (url) => {
            setImageUrlDetail(url);
        });
    }
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传jpeg或者png的格式的图片！');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('图片最大10M!');
        }
        return isJpgOrPng && isLt10M;
    };
    const columns = [
        {
            title: '项目名称',
            dataIndex: 'serviceName',
            key: 'serviceName',
            align: 'center',
        },
        {
            title: '价格（RMB）',
            dataIndex: 'serviceMoney',
            key: 'serviceMoney',
            align: 'center',
        },
        {
            title: '服务时长（分钟）',
            dataIndex: 'serviceTime',
            key: 'serviceTime',
            align: 'center',
        },
        {
            title: '项目描述',
            dataIndex: 'serviceDetail',
            key: 'serviceDetail',
            align: 'center',
        },
        {
            title: '创建时间',
            dataIndex: 'insertTime',
            key: 'insertTime',
            align: 'center',
            render: (_, record) => (
                <span>
                    {moment(record.insertTime).format('YYYY-MM-DD HH:MM:SS')}
                </span>
            ),
        },
        {
            title: '图片',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (_, record) => (
                <Image
                    width={200}
                    height={50}
                    src={record.serviceImage}
                />
            ),
        },
        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <Space size="large">
                    <EditOutlined onClick={() => editComm(record)} title='编辑' style={{ color: '#cfae9f' }} />
                    <Popconfirm
                        title="确定要删除?"
                        onConfirm={() => confirm(record)}
                        onCancel={cancel}
                        okText="是"
                        cancelText="否"
                    >
                        <DeleteOutlined title='删除' style={{ color: 'red' }} />
                    </Popconfirm>

                </Space>
            ),
        },
    ];
    const uploadButton = (
        <div>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                点击上传
            </div>
        </div>
    );
    return (
        <div className={styles.home}>
            <Menus select={'commodity'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Button type="primary" onClick={addList}>新增</Button>
                </div>
                <div className={styles.table}>
                    <Table scroll={{ y: '45rem' }} loading={loading} dataSource={dataList} columns={columns} rowKey={(record) => record.serviceId} pagination={false} />
                </div>
            </div>
            <Modal
                title={modelTitle}
                visible={isModalOpen}
                destroyOnClose={true}
                centered
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={uploadImgeLoading}
            >
                <Form
                    name="basic"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 14,
                    }}
                    onFinish={onFinish}
                    form={form}
                    autoComplete='off'
                >

                    <Form.Item
                        name='serviceName'
                        label="项目名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入项目名称',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name='serviceMoney' label="价格" rules={[
                        {
                            required: true,
                            message: '请输入项目价格',
                        },
                    ]}>
                        <Input prefix="￥" suffix="RMB" />
                    </Form.Item>
                    <Form.Item name='serviceTime' label="服务时长" rules={[
                        {
                            required: true,
                            message: '请输入项目服务时长',
                        },
                    ]}>
                        <Input suffix="分钟" />
                    </Form.Item>
                    <Form.Item name='selected' label="选择人数" rules={[
                        {
                            required: true,
                            message: '请输入项目人数',
                        },
                    ]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="serviceDetail" label="项目描述"
                        rules={[
                            {
                                required: true,
                                message: '请输入项目描述',
                            },
                        ]}
                    >
                        <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item name='serviceImage' label="图片" rules={[
                        {
                            required: true,
                            message: '请上传图片',
                        },
                    ]}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            onChange={handleChange}
                            beforeUpload={beforeUpload}
                            accept='.png,.jpg,.Webp,.jpeg,.bmp,.psd,.dxf'
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Form.Item>
                    <Form.Item name='serviceImageDetail' label="详情图片"
                        rules={[
                            {
                                required: true,
                                message: '请上传详情图片',
                            },
                        ]}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            onChange={handleChangeDetail}
                            beforeUpload={beforeUpload}
                            accept='.png,.jpg,.Webp,.jpeg,.bmp,.psd,.dxf'
                        >
                            {imageUrlDetail ? (
                                <img
                                    src={imageUrlDetail}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            <Record />
        </div>
    )
}

export default Index