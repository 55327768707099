//客户管理
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Button, DatePicker, Space, Table, message } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import util from '../../util/util';
import styles from './index.less'
import Menus from '@/component/menu'
import Record from '@/component/record'
const { RangePicker } = DatePicker;
const Index = () => {
    const [form] = Form.useForm();
    const onReset = () => {
        form.resetFields();
    };
    const [userAnalysisData, setuserAnalysisData] = useState('');
    const [userListData, setuserListData] = useState([]);
    const [total, settotal] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setloading] = useState(false);
    useEffect(() => {
        userAnalysis()

    }, [])
    useEffect(() => {
        userList()
    }, [pageNum, pageSize])
    const userAnalysis = () => {
        util.httpRequest({
            url: util.API + `/user/userAnalysis`,
            method: 'POST',
        }).then((res) => {
            if (res.code === 200) {
                setuserAnalysisData(res.data)
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const userList = () => {
        setloading(true)
        util.httpRequest({
            url: util.API + `/user/userList`,
            method: 'POST',
            data: {
                pageNum,
                pageSize,
            }
        }).then((res) => {
            if (res.code === 200) {
                setuserListData(res.data.list)
                settotal(res.data.total)
                setloading(false)
            } else {
                setloading(false)
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }

    const columns = [
        {
            title: '用户ID',
            dataIndex: 'openId',
            key: 'openId',
            align: 'center',
        },
        {
            title: '用户昵称',
            dataIndex: 'nickName',
            key: 'nickName',
            align: 'center',
        },
        {
            title: '注册时间',
            dataIndex: 'registerTime',
            key: 'registerTime',
            align: 'center',
        },
        {
            title: '最近登录',
            dataIndex: 'lastLoginTime',
            key: 'lastLoginTime',
            align: 'center',
        },
    ];
    return (
        <div className={styles.home}>
            <Menus select={'crew'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Row gutter={16}>

                        <Col span={6}>
                            用户总数：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}> {userAnalysisData?.totalUserNum}</span>

                        </Col>
                        <Col span={6}>
                            今日新增：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}>
                                {userAnalysisData?.todayUserNum}
                            </span>
                        </Col>
                    </Row>
                </div>
                <div className={styles.table}>
                    <Table
                        dataSource={userListData}
                        columns={columns}
                        rowKey={(record) => record.openId}
                        loading={loading}
                        scroll={{ y: '41rem' }}
                        pagination={{
                            pageSize: pageSize,
                            current: pageNum, // 默认显示第几页
                            total: total, // 共计几条
                            showTotal: total => `共计 ${total} 个`,
                            showSizeChanger: true, // 是否展示pageSize切换器                          
                            onChange: (page, limit) => {
                                setPageNum(page);
                                setPageSize(limit);
                            }
                        }}
                    />
                </div>
            </div>
            <Record />
        </div>
    )
}

export default Index