
import React, { useEffect, useState } from 'react';
import { Menu, } from 'antd';
import { PoweroffOutlined, } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'
import styles from './index.less'
const Index = (props) => {
    const [isRole, setisRole] = useState(sessionStorage.getItem('isRole'));
    const history = useHistory()
    useEffect(() => {
        // getMenu()
    }, [])
    const layOut = () => {
        sessionStorage.clear()
        history.push('/login')
    }
    const checkPage = (param) => {
        switch (param.key) {
            case 'order':
                history.push('/home')
                break;
            case 'crew':
                history.push('/crew')
                break;
            case 'technician':
                history.push('/technician')
                break;
            case 'distribution':
                history.push('/distribution')
                break;
            case 'commodity':
                history.push('/commodity')
                break;
            case 'statistics':
                history.push('/statistics')
                break;
            case 'coupon':
                history.push('/coupon')
                break;
            case 'protocol':
                history.push('/protocol')
                break;
            case 'broadcast':
                history.push('/broadcast')
                break;
            case 'cashrecord':
                history.push('/cashrecord')
                break;
            case 'promotion':
                history.push('/promotion')
                break;
            case 'profit':
                history.push('/profit')
                break;
            case 'pusercenter':
                history.push('/pusercenter')
                break;
            case 'withdrawalrecord':
                history.push('/withdrawalrecord')
                break;
            case 'withdrawalrecordt':
                history.push('/withdrawalrecordt')
                break;
            case 'feedback':
                history.push('/feedback')
                break;
            case 'promotionitem':
                history.push('/promotionitem')
                break;
            default:
                history.push('/usercenter')
        }
        // if (param.key === 'order') {

        // } else if (param.key === 'crew') {
        //     history.push('/crew')
        // }
    }
    const items = [
        { label: '订单管理', key: 'order' },
        { label: '用户管理', key: 'crew' },
        { label: '理疗师管理', key: 'technician' },
        { label: '店铺管理', key: 'distribution' },
        { label: '推广管理', key: 'promotion' },
        { label: '服务项目', key: 'commodity' },//优惠卷 、打车费
        // { label: '收支统计', key: 'statistics' },
        { label: '优惠卷', key: 'coupon' },
        { label: '车费设置', key: 'protocol' },
        { label: '轮播图', key: 'broadcast' },
        { label: '提现记录', key: 'cashrecord' },
        { label: '反馈意见', key: 'feedback' },
        // { label: '提现记录', key: 'withdrawalrecord' },
        { label: '系统管理', key: 'usercenter' },
    ];
    const itemsp = [
        { label: '用户管理', key: 'crew' },
        { label: '利润统计', key: 'profit' },
        { label: '推广管理', key: 'promotionitem' },
        { label: '提现记录', key: 'withdrawalrecordt' },
        { label: '个人中心', key: 'pusercenter' },
    ]
    return (
        <div className={styles.home}>
            <div className={styles.logo}>
                晓爱到家
            </div>
            <div className={styles.menu}>
                <Menu items={isRole === '1' ? items : itemsp} mode="horizontal" selectedKeys={[props.select]} theme='dark' onClick={checkPage} />
            </div>
            <div className={styles.layout}>
                <div className={styles.name}>欢迎:{sessionStorage.getItem('username')}</div>
                <div className={styles.out} onClick={layOut}>
                    <PoweroffOutlined className={styles.lay} />
                </div>
            </div>

        </div>
    )
}
export default Index