/*
 * @Author: zhangwangwang
 * @Date: 2021-06-04 14:17:30
 * @Last Modified by: zhangwangwang
 * @Last Modified time: 2023-05-11 10:02:05
 */

import configIndex from "./config/index.js";
import { httpRequest, httpRequestSync } from "./httpRequest.js";
import { mergeCells } from "./mergeCells";
import { parse } from "querystring";
const getPageQuery = () => parse(window.location.href.split("?")[1]);
const data = {
  httpRequest,
  httpRequestSync,
  mergeCells,
  getPageQuery,
  ...configIndex,
};
export default data;
