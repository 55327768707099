//轮播图
import React, { useEffect, useState } from 'react';
import { Popconfirm, Modal, Upload, Input, Button, DatePicker, Space, Table, message, Image } from 'antd';
import { EditOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './index.less'
import Menus from '@/component/menu'
import util from '../../util/util';
import Record from '@/component/record'
const Index = () => {
    const [dataList, setdataList] = useState([]);
    const [loading, setloading] = useState(false);
    const [isModalOpen, setisModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [imageInfo, setImageInfo] = useState(null);
    const [uploadImgeLoading, setuploadImgeLoading] = useState(false);
    const [modelTitle, setModelTitle] = useState('');
    const [bannerId, setBannerId] = useState(1);
    useEffect(() => {
        getBroadcastList()
    }, [])
    const getBroadcastList = () => {
        setloading(true);
        util.httpRequest({
            url: util.API + `/admin/bannerList`,
            method: 'GET',
            data: {

            }
        }).then((res) => {
            if (res.code === 200) {
                setdataList(res.data)
            } else {
                message.error(res.message)
            }
            setloading(false);
        }).catch((err) => {

        });
    }
    const addList = () => {
        setModelTitle('新增轮播图')
        setisModalOpen(true)
    }
    const confirm = (param) => {
        deleteBro(param)
    };
    const cancel = (e) => {

    };
    const editordeleteBro = (param) => {
        setModelTitle('编辑轮播图')
        setImageUrl(param.imageUrl)
        setisModalOpen(true)
        setBannerId(param.bannerId)
    }
    const deleteBro = (param) => {
        let formData = new FormData();
        formData.append('type', 2);
        formData.append('bannerId', param.bannerId);
        util.httpRequest({
            url: util.API + `/admin/bannerEdit`,
            method: 'POST',
            data: formData

        }).then((res) => {
            if (res.code === 200) {
                getBroadcastList()
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const handleOk = () => {
        setuploadImgeLoading(true);
        if (imageInfo) {
            let formData = new FormData();
            if (modelTitle === '新增轮播图') {
                formData.append('file', imageInfo);
                formData.append('toUrl', '');
                formData.append('type', 1);
            } else {
                formData.append('file', imageInfo);
                formData.append('toUrl', '');
                formData.append('type', 3);
                formData.append('bannerId', bannerId);

            }
            util.httpRequest({
                url: util.API + `/admin/bannerEdit`,
                method: 'POST',
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                if (res.code === 200) {
                    message.success(res.message)
                    setisModalOpen(false)
                    setImageUrl('')
                    setImageInfo(null)
                    getBroadcastList()

                } else {
                    message.error(res.message)
                }
                setuploadImgeLoading(false)

            }).catch((err) => {

            });

        } else {
            setisModalOpen(false)
            setuploadImgeLoading(false)
        }
    }
    const handleCancel = () => {
        setImageUrl('')
        setImageInfo(null)
        setisModalOpen(false)
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handleChange = (info) => {
        setImageInfo(info.file.originFileObj)
        getBase64(info.file.originFileObj, (url) => {
            setImageUrl(url);
        });
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只能上传jpeg或者png的格式的图片！');
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error('图片最大10M!');
        }
        return isJpgOrPng && isLt10M;
    };
    const columns = [
        {
            title: '图片',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            render: (_, record) => (
                <Image
                    width={200}
                    height={50}
                    src={record.imageUrl}
                />
            ),
        },
        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <Space size="large">
                    <EditOutlined onClick={() => editordeleteBro(record)} title='编辑' style={{ color: '#cfae9f' }} />
                    <Popconfirm
                        title="确定要删除?"
                        onConfirm={() => confirm(record)}
                        onCancel={cancel}
                        okText="是"
                        cancelText="否"
                    >
                        <DeleteOutlined title='删除' style={{ color: 'red' }} />
                    </Popconfirm>

                </Space>
            ),
        },
    ];
    const uploadButton = (
        <div>
            <div
                style={{
                    marginTop: 8,
                }}
            >
                点击上传
            </div>
        </div>
    );
    return (
        <div className={styles.home}>
            <Menus select={'broadcast'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Button type="primary" onClick={addList}>新增</Button>
                </div>
                <div className={styles.table}>
                    <Table scroll={{ y: '45rem' }} loading={loading} dataSource={dataList} columns={columns} rowKey={(record) => record.bannerId} pagination={false} />
                </div>
            </div>
            <Modal
                title={modelTitle}
                visible={isModalOpen}
                destroyOnClose
                centered
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={uploadImgeLoading}
            >
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}

                    accept='.png,.jpg,.Webp,.jpeg,.bmp,.psd,.dxf'
                >
                    {imageUrl ? (
                        <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    ) : (
                        uploadButton
                    )}
                </Upload>
            </Modal>
            <Record />
        </div>
    )
}

export default Index