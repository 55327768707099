//店铺
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Modal, Button, DatePicker, Space, Table, message, Select, Image, InputNumber } from 'antd';
import { UsergroupAddOutlined, AppstoreOutlined, SettingOutlined, FormOutlined } from '@ant-design/icons';
import styles from './index.less'
import util from '../../util/util';
import Menus from '@/component/menu'
import Record from '@/component/record'
const { RangePicker } = DatePicker;
const { Option } = Select;
const Index = () => {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [key, setKey] = useState(2);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shopId, setShopId] = useState('');
    useEffect(() => {
        getData(key)
    }, [key])
    const getData = (enable) => {
        setTableLoading(true)
        util.httpRequest({
            url: util.API + `/admin/agentList`,
            method: 'POST',
            data: {
                enable
            }
        }).then((res) => {
            if (res.code === 200) {
                setDataSource(res.data)
            } else {
                message.error(res.message)
            }
            setTableLoading(false)
        }).catch((err) => {

        });
    }
    const onReset = () => {
        form.resetFields();
    };
    // const onFinish = (values) => {
    //     getData(values.status)
    // }
    const onChangeSelect = (value) => {
        setKey(value)
    }
    const approvepro1 = (param) => {
        Modal.confirm({
            title: "确认审核通过?",
            cancelText: "取消",
            okText: "确定",
            centered: 'center',
            onCancel: () => {

            },
            onOk: () => {
                approvepro(param)
            },
        });
    }
    const approvepro = (param) => {
        util.httpRequest({
            url: util.API + `/agent/approve?agentId=${param.agentId}`,
            method: 'POST',

        }).then((res) => {
            if (res.code === 200) {
                message.success(res.message)
                getData(form.getFieldValue('status'))
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }


    const handleOk = () => {
        form1.submit()
        // setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const setProportion = (obj) => {
        form1.setFieldsValue({
            firstDivide: obj.firstDivide,
            addDivide: obj.addDivide
        })
        setShopId(obj.agentId)
        setIsModalOpen(true);
    }
    const onFinish = (values) => {
        util.httpRequest({
            url: util.API + `/agent/divideSet`,
            method: 'POST',
            data: {
                firstDivide: values.firstDivide,
                addDivide: values.addDivide,
                shopId,
            }
        }).then((res) => {
            if (res.code === 200) {
                setIsModalOpen(false);
                getData(form.getFieldValue('status'))
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'agentId',
            key: 'agentId',
            align: 'center',
            fixed: 'left',
            width: 100,
        },
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
            fixed: 'left',
            width: 150,
        },
        {
            title: '店铺码',
            dataIndex: 'inviteCode',
            key: 'inviteCode',
            align: 'center',
            width: 200,
        },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
            align: 'center',
            width: 200,
        },
        {
            title: '首单分成比例',
            dataIndex: 'firstDivide',
            key: 'firstDivide',
            align: 'center',
            width: 200,
            render: (_, record) => {
                if (record.firstDivide) {
                    return (
                        <span>
                            {record.firstDivide}%
                        </span>
                    )
                } else {
                    return '--'
                }
            }
        },
        {
            title: '加钟分成比例',
            dataIndex: 'addDivide',
            key: 'addDivide',
            align: 'center',
            width: 200,
            render: (_, record) => {
                if (record.addDivide) {
                    return (
                        <span>
                            {record.addDivide}%
                        </span>
                    )
                } else {
                    return '--'
                }
            }
        },
        {
            title: '审核状态',
            dataIndex: 'enable',
            key: 'enable',
            align: 'center',
            width: 200,
            render: (_, obj) => {
                if (obj.enable === 0) {
                    return '待审核'
                } else if (obj.enable === 1) {
                    return '审核通过'
                }
            }
        },
        {
            title: '登录账号',
            dataIndex: 'userAccount',
            key: 'userAccount',
            align: 'center',
            width: 200,
        },
        {
            title: '密码',
            dataIndex: 'password',
            key: 'password',
            align: 'center',
            width: 100,
        },

        {
            title: '申请时间',
            dataIndex: 'insertTime',
            key: 'insertTime',
            align: 'center',
            width: 200,
        },
        {
            title: '营业执照',
            dataIndex: 'ext1',
            key: 'ext1',
            align: 'center',
            width: 200,
            render: (_, record) => (
                <Image
                    width={180}
                    height={50}
                    src={record.ext1}
                />
            ),
        },
        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            fixed: 'right',
            width: 200,
            render: (_, obj) => {
                if (obj.enable === 0) {
                    return <Space>
                        <UsergroupAddOutlined title='审核' onClick={() => approvepro1(obj)} style={{ color: '#cfae9f', fontSize: '1.5rem' }} />
                        <FormOutlined title='分成比例' onClick={() => setProportion(obj)} style={{ color: '#cfae9f', fontSize: '1.5rem' }} />
                    </Space>
                } else if (obj.enable === 1) {
                    return <Space>
                        <UsergroupAddOutlined title='审核' style={{ color: '#ccc', fontSize: '1.5rem' }} />
                        <FormOutlined title='分成比例' onClick={() => setProportion(obj)} style={{ color: '#cfae9f', fontSize: '1.5rem' }} />
                    </Space>
                }
            }
        },
    ];
    return (
        <div className={styles.home}>
            <Menus select={'distribution'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        // onFinish={onFinish}
                        initialValues={{
                            status: 2,
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="status"
                                    label="审核状态"
                                >
                                    <Select onChange={onChangeSelect}>
                                        <Option value={0}>待审核</Option>
                                        <Option value={1}>审核通过</Option>
                                        <Option value={2}>全部</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                {/* <Form.Item
                                    name="status"
                                    label="订单状态"
                                >
                                    <Input />
                                </Form.Item> */}
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item
                                    name="datte"
                                    label="订单时间"
                                >
                                    <RangePicker />
                                </Form.Item>
                            </Col> */}
                            <Col span={6} style={{ textAlign: 'end' }}>
                                {/* <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Space size={24}>
                                        <Button type="primary" htmlType="submit">
                                            查询
                                        </Button>
                                        <Button htmlType="button" onClick={onReset}>
                                            重置
                                        </Button>
                                    </Space>
                                </Form.Item> */}
                            </Col>
                        </Row>

                    </Form>
                </div>
                <div className={styles.table}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        loading={tableLoading}
                        rowKey={record => record.artificerId}
                        scroll={{
                            x: 2000,
                            y: '43rem',
                        }}
                    />
                </div>
            </div>
            <Modal
                title="设置分成比例"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    onFinish={onFinish}
                    form={form1}
                >
                    <Form.Item
                        label="首单分成比例"
                        name="firstDivide"
                        rules={[
                            {
                                required: true,
                                message: '请输入首单分成比例',
                            },
                        ]}
                    >
                        <InputNumber min={50} max={100} style={{ width: "100%" }} addonAfter="%" />
                    </Form.Item>

                    <Form.Item
                        label="加钟分成比例"
                        name="addDivide"
                        rules={[
                            {
                                required: true,
                                message: '请输入加钟分成比例',
                            },
                        ]}
                    >
                        <InputNumber min={50} max={100} style={{ width: "100%" }} addonAfter="%" />
                    </Form.Item>
                </Form>

            </Modal>
            <Record />
        </div>
    )
}

export default Index