//优惠卷
import React, { useEffect, useState } from 'react';
import Menus from '@/component/menu'
import { Form, Modal, Tabs, Input, Button, Select, Space, Table, message } from 'antd';
import { EditOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import util from '../../util/util';
import styles from './index.less'
import Record from '@/component/record'
const { TextArea } = Input;
const { Option } = Select;
const Index = () => {
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [shareCouponData, setShareCouponData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openTitle, setOpenTitle] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [couponId, setCouponId] = useState(1);
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        setTableLoading(true)
        util.httpRequest({
            url: util.API + `/admin/couponList`,
            method: 'POST',
        }).then((res) => {
            if (res.code === 200) {
                if (res.data) {
                    setShareCouponData([res.data.shareCoupon])
                    setDataSource(res.data.signInCoupon)
                }
            } else {
                message.error(res.message)
            }
            setTableLoading(false)
        }).catch((err) => {

        });
    }
    const addList = () => {
        setOpenTitle('新增')
        setIsModalOpen(true)
    }

    const handleOk = () => {
        form.submit()
    };
    const onFinish = (values) => {
        let param = { ...values, couponId: couponId }
        setSubmitLoading(true)
        util.httpRequest({
            url: util.API + `/admin/couponEdit`,
            method: 'POST',
            data: openTitle === '新增' ? values : param
        }).then((res) => {
            if (res.code === 200) {
                message.success(res.message)
                getData()
                setIsModalOpen(false)
                form.resetFields()

            } else {
                message.error(res.message)

            }
            setSubmitLoading(false)
        }).catch((err) => {

        });
    }
    const edit = (record) => {
        setOpenTitle('编辑')
        setCouponId(record.couponId)
        setIsModalOpen(true)

        form.setFieldsValue({
            ...record
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields()
    }
    const columns = [
        {
            title: '优惠卷金额',
            dataIndex: 'couponMoney',
            key: 'couponMoney',
            align: 'center',
        },
        {
            title: '满减',
            dataIndex: 'useStandard',
            key: 'useStandard',
            align: 'center',
        },
        {
            title: '优惠卷类型',
            dataIndex: 'couponType',
            key: 'couponType',
            align: 'center',
            render: (_, obj) => {
                if (obj.couponType === 1) {
                    return '注册优惠卷'
                } else {
                    return '分享优惠卷'
                }
            }
        },
        {
            title: '创建时间',
            dataIndex: 'insertTime',
            key: 'insertTime',
            align: 'center',
        },

        {
            title: '操作',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            render: (_, record) => (
                <Space size="large">
                    <EditOutlined onClick={() => edit(record)} title='编辑' style={{ color: '#cfae9f' }} />
                </Space>
            ),
        },
    ];
    return (
        <div className={styles.home}>
            <Menus select={'coupon'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Button type="primary" onClick={addList}>新增</Button>
                </div>
                <div className={styles.table}>
                    <Tabs>
                        <Tabs.TabPane tab="分享优惠卷" key="item-1">
                            <Table
                                dataSource={shareCouponData}
                                columns={columns}
                                pagination={false}
                                loading={tableLoading}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="注册优惠卷" key="item-2">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                loading={tableLoading}
                            />
                        </Tabs.TabPane>
                    </Tabs>

                </div>
            </div>
            <Modal
                title={openTitle}
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                centered
                confirmLoading={submitLoading}
            >
                <Form
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    autoComplete='off'
                    layout="horizontal"
                    form={form}
                    onFinish={onFinish}

                >
                    <Form.Item name='couponMoney' label='优惠卷金额' rules={[
                        {
                            required: true,
                            message: '请选择城市',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='useStandard' label='满减' rules={[
                        {
                            required: true,
                            message: '请填写起步价',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='couponType' label='优惠卷类型' rules={[
                        {
                            required: true,
                            message: '请填写起步价',
                        },
                    ]}>
                        <Select disabled={openTitle === '新增' ? false : true}>
                            <Option value={1}>注册优惠卷</Option>
                            <Option value={2}>分享优惠卷</Option>
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>
            <Record />
        </div>
    )
}

export default Index