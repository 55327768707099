//车费
import React, { useEffect, useState } from 'react';
import Menus from '@/component/menu'
import { Form, Modal, Col, Input, Button, DatePicker, Space, Table, message } from 'antd';
import util from '../../util/util';
import styles from './index.less'
import Record from '@/component/record'
const { TextArea } = Input;
const Index = () => {
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openTitle, setOpenTitle] = useState('新增');
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        util.httpRequest({
            url: util.API + `/admin/taxiFeesInfo`,
            method: 'POST',
        }).then((res) => {
            if (res.code === 200) {
                setDataSource(res.data)
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const addList = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        form.submit()
    };
    const onFinish = (values) => {
        util.httpRequest({
            url: util.API + `/admin/taxiFees`,
            method: 'POST',
            data: values
        }).then((res) => {
            if (res.code === 200) {
                message.success(res.message)
                getData()
                setIsModalOpen(false)
                form.resetFields()
            } else {
                message.error(res.message)

            }
        }).catch((err) => {

        });
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields()
    }
    const columns = [
        {
            title: '城市',
            dataIndex: 'cityName',
            key: 'cityName',
            align: 'center',
        },
        {
            title: '起步价(元)',
            dataIndex: 'foundation',
            key: 'foundation',
            align: 'center',
        },
        {
            title: '白天每公里(元)',
            dataIndex: 'dayUnit',
            key: 'dayUnit',
            align: 'center',
        },
        {
            title: '晚上每公里(元)',
            dataIndex: 'nightUnit',
            key: 'nightUnit',
            align: 'center',
        },
        {
            title: '创建时间',
            dataIndex: 'editTime',
            key: 'editTime',
            align: 'center',
        },
    ];
    return (
        <div className={styles.home}>
            <Menus select={'protocol'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Button type="primary" onClick={addList}>新增</Button>
                </div>
                <div className={styles.table}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            </div>
            <Modal
                title={openTitle}
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                centered
            >
                <Form
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    autoComplete='off'
                    layout="horizontal"
                    form={form}
                    onFinish={onFinish}

                >
                    <Form.Item name='cityName' label='城市' rules={[
                        {
                            required: true,
                            message: '请选择城市',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='foundation' label='起步价' rules={[
                        {
                            required: true,
                            message: '请填写起步价',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='dayUnit' label='白天每公里' rules={[
                        {
                            required: true,
                            message: '请填写白天每公里',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='nightUnit' label='晚上每公里' rules={[
                        {
                            required: true,
                            message: '请填写晚上每公里',
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Record />
        </div>
    )
}

export default Index