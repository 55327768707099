import React, { useEffect, useState } from 'react';
import { Tabs, Modal, Form, Col, Input, Button, DatePicker, Space, Table, message, Spin } from 'antd';
import Menus from '@/component/menu'
import util from '../../util/util';
import styles from './index.less'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import Record from '@/component/record'
const { TextArea } = Input;
const Index = () => {
    useEffect(() => {
        getData('1')
    }, [])
    const [data, setData] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openTitle, setOpenTitle] = useState('');
    const [form] = Form.useForm();
    const [key, setKey] = useState(1);
    const [treeLoading, setTreeLoading] = useState(false);
    const [editorState, setEditorState] = useState('');
    const [editorState1, setEditorState1] = useState('');
    const showModal = (key, param) => {
        setKey(key)
        setIsModalOpen(true);
        setOpenTitle(param)
        form.setFieldsValue({
            textContent: data?.context
        })
    };
    const handleOk = () => {
        form.submit()
    };
    const onFinish = (values) => {
        util.httpRequest({
            url: util.API + `/admin/textEdit`,
            method: 'POST',
            data: {
                type: key,
                textContent: values.textContent
            }
        }).then((res) => {
            if (res.code === 200) {
                getData(key)
                message.success(res.message)
                setIsModalOpen(false);
            }
        }).catch((err) => {

        });
    }
    const onFinishs = (key) => {
        let param = ''
        if (key === '1') {
            param = editorState
        } else {
            param = editorState1
        }
        util.httpRequest({
            url: util.API + `/admin/textEdit`,
            method: 'POST',
            data: {
                type: key,
                textContent: param
            }
        }).then((res) => {
            if (res.code === 200) {
                getData(key)
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        }).catch((err) => {

        });
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const getData = (type) => {
        setTreeLoading(true);
        util.httpRequest({
            url: util.API + `/admin/textInfo`,
            method: 'POST',
            data: {
                type,
            }
        }).then((res) => {
            if (res.code === 200) {
                if (type === '1') {
                    setEditorState(BraftEditor.createEditorState(res.data.context))
                } else if (type === '2') {
                    setEditorState1(BraftEditor.createEditorState(res.data.context))
                } else {
                    setData(res.data)
                }
                setTreeLoading(false);
            } else {
                setTreeLoading(false);
            }
        }).catch((err) => {

        });
    }
    const changeTabs = (key) => {
        getData(key)
    }
    const handleEditorChange = (editorState) => {
        setEditorState(editorState.toHTML())
    }
    const handleEditorChange1 = (editorState) => {
        setEditorState1(editorState.toHTML())
    }
    return (
        <div className={styles.home}>
            <Menus select={'usercenter'} />
            <div className={styles.content}>
                <Spin tip="加载中..." spinning={treeLoading}>
                    <Tabs onChange={changeTabs}>
                        <Tabs.TabPane tab="用户协议" key={'1'}>
                            <div className={styles.item2}>
                                <BraftEditor
                                    contentStyle={{ height: '37rem' }}
                                    value={editorState}
                                    onChange={handleEditorChange}
                                />
                            </div>
                            <div className={styles.item3}>
                                <Button onClick={() => onFinishs('1')} type="primary">提交</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="下单须知" key={'2'}>
                            <div className={styles.item2}>
                                <BraftEditor
                                    contentStyle={{ height: '37rem' }}
                                    value={editorState1}
                                    onChange={handleEditorChange1}
                                />
                            </div>
                            <div className={styles.item3}>
                                <Button onClick={() => onFinishs('2')} type="primary">提交</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="客服电话" key={'3'}>

                            <div className={styles.item2}>
                                {data?.context}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(3, '客服电话')}>新增/修改客服电话</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="客服微信" key={'4'}>

                            <div className={styles.item2}>
                                {data?.context}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(4, '客服微信')}>新增/修改客服微信</Button>
                            </div>
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane tab="技师分成比例" key={5}>

                            <div className={styles.item2}>
                                {data?.context}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(5, '技师分成比例')}>新增/修改技师分成比例</Button>
                            </div>
                        </Tabs.TabPane> */}
                        <Tabs.TabPane tab="店铺首单分成比例" key={'6'}>
                            <div className={styles.item2}>
                                {data?.context + '%'}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(6, '店铺首单分成比例')}>新增/修改店铺分成比例</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="店铺加钟打赏分成比例" key={'7'}>
                            <div className={styles.item2}>
                                {data?.context + '%'}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(7, '店铺加钟打赏分成比例')}>新增/修改店铺加钟打赏分成比例</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="推广分成比例" key={'5'}>
                            <div className={styles.item2}>
                                {data?.context + '%'}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(5, '推广分成比例')}>新增/修改推广分成比例</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="技师邀请下单首单分成比例" key={'8'}>
                            <div className={styles.item2}>
                                {data?.context + '%'}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(8, '技师邀请下单首单分成比例')}>新增/修改技师邀请下单首单分成比例</Button>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="技师邀请下单加钟分成比例" key={'9'}>
                            <div className={styles.item2}>
                                {data?.context + '%'}
                            </div>
                            <div className={styles.item3}>
                                <Button type="primary" onClick={() => showModal(9, '技师邀请下单加钟分成比例')}>新增/修改技师邀请下单加钟分成比例</Button>
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </Spin>
            </div>
            <Modal
                title={openTitle}
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                centered
            >
                <Form
                    // labelCol={{
                    //     span: 4,
                    // }}
                    // wrapperCol={{
                    //     span: 20,
                    // }}
                    layout="horizontal"
                    form={form}
                    onFinish={onFinish}

                >
                    <Form.Item name='textContent' label={openTitle} rules={[
                        {
                            required: true,
                            message: '请输入文本',
                        },
                    ]}>
                        <TextArea rows={6} />
                    </Form.Item>
                </Form>
            </Modal>
            <Record />
        </div>
    )
}

export default Index