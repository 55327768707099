/*
 * @Author: zhangwangwang
 * @Date: 2021-07-20 11:26:47
 * @Last Modified by: zhangwangwang
 * @Last Modified time: 2024-08-19 17:01:48
 */

module.exports = {
  API: `/dtdtest`,
  // API: `/dtd`,
  // API: `/api`,
};
