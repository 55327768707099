import React, { lazy } from "react";
import Login from "@/pages/login";
import Home from "@/pages/home";
import Crew from "@/pages/crew";
import Broadcast from "@/pages/broadcast";
import Commodity from "@/pages/commodity";
import Coupon from "@/pages/coupon";
import Distribution from "@/pages/distribution";
import Protocol from "@/pages/protocol";
import Statistics from "@/pages/statistics";
import Technician from "@/pages/technician";
import Usercenter from "@/pages/usercenter";
import Cashrecord from "@/pages/cashrecord";
import Promotion from "@/pages/promotion";
import Withdrawalrecord from "@/pages/withdrawalrecord";
import Feedback from "@/pages/feedback";
import Error from "@/pages/error";
export const routerMap = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    auth: true,
  },
  {
    path: "/login",
    name: "Login",
    title: "登录",
    component: Login,
  },
  {
    path: "/crew",
    name: "Crew",
    component: Crew,
    auth: true,
  },
  {
    path: "/broadcast",
    name: "Broadcast",
    component: Broadcast,
    auth: true,
  },
  {
    path: "/commodity",
    name: "Commodity",
    component: Commodity,
    auth: true,
  },
  {
    path: "/coupon",
    name: "Coupon",
    component: Coupon,
    auth: true,
  },
  {
    path: "/distribution",
    name: "Distribution",
    component: Distribution,
    auth: true,
  },
  {
    path: "/protocol",
    name: "Protocol",
    component: Protocol,
    auth: true,
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    auth: true,
  },
  {
    path: "/technician",
    name: "Technician",
    component: Technician,
    auth: true,
  },
  {
    path: "/usercenter",
    name: "Usercenter",
    component: Usercenter,
    auth: true,
  },
  {
    path: "/cashrecord",
    name: "Cashrecord",
    component: Cashrecord,
    auth: true,
  },
  {
    path: "/promotion",
    name: "Promotion",
    component: Promotion,
    auth: true,
  },
  {
    path: "/withdrawalrecord",
    name: "Withdrawalrecord",
    component: Withdrawalrecord,
    auth: true,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    auth: true,
  },
  {
    path: "/error",
    name: "Error",
    component: Error,
    auth: true,
    title: "未找到",
    meta: {},
  },
];
