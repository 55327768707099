import React, { useEffect, useState } from 'react';
import { Tabs, Modal, Form, Row, Col, Input, Button, DatePicker, Space, Table, message, Select, Popconfirm } from 'antd';
import Menus from '@/component/menu'
import util from '../../util/util';
import styles from './index.less'
import Record from '@/component/record'
const Index = () => {

    const [data, setData] = useState([]);
    const [dataf, setDataf] = useState([]);
    const [datat, setDatat] = useState([]);
    const [key, setKey] = useState(0);
    const [keyf, setKeyf] = useState(0);
    const [keyt, setKeyt] = useState(0);
    const [loading, setloading] = useState(false);

    const columns = [
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
        },
        {
            title: '提交申请时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            align: 'center',
        },
        {
            title: '审核时间',
            dataIndex: 'approveTime',
            key: 'approveTime',
            align: 'center',
        },
        {
            title: '银行卡号',
            dataIndex: 'cardNum',
            key: 'cardNum',
            align: 'center',
        },
        {
            title: '银行名称',
            dataIndex: 'bankName',
            key: 'bankName',
            align: 'center',
        },
        {
            title: '持卡人姓名',
            dataIndex: 'cardUserName',
            key: 'cardUserName',
            align: 'center',
        },
        {
            title: '开户行地址',
            dataIndex: 'bankAddress',
            key: 'bankAddress',
            align: 'center',
        },

        {
            title: '提现金额(￥)',
            dataIndex: 'casheMoney',
            key: 'casheMoney',
            align: 'center',
        },
        {
            title: '应打款金额(￥)',
            dataIndex: 'availableMoney',
            key: 'availableMoney',
            align: 'center',
        },
        {
            title: '手续费(￥)',
            dataIndex: 'casheCharge',
            key: 'casheCharge',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (_, record) => {
                if (key === 0) {
                    return <Popconfirm
                        title={'确认打' + record.availableMoney + '元'}
                        onConfirm={() => confirm(record.casheId)}
                        onCancel={cancel}
                        okText="是"
                        cancelText="否"
                    >
                        <span style={{ color: '#cfae9f', cursor: 'pointer' }}>打款</span>
                    </Popconfirm>


                } else {
                    return <span style={{ color: '#CCC', cursor: 'pointer' }}>已打款</span>
                }
            }
        },
    ];
    const columnsf = [
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
        },
        {
            title: '提交申请时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            align: 'center',
        },
        {
            title: '审核时间',
            dataIndex: 'approveTime',
            key: 'approveTime',
            align: 'center',
        },
        {
            title: '银行卡号',
            dataIndex: 'cardNum',
            key: 'cardNum',
            align: 'center',
        },
        {
            title: '银行名称',
            dataIndex: 'bankName',
            key: 'bankName',
            align: 'center',
        },
        {
            title: '持卡人姓名',
            dataIndex: 'cardUserName',
            key: 'cardUserName',
            align: 'center',
        },
        {
            title: '开户行地址',
            dataIndex: 'bankAddress',
            key: 'bankAddress',
            align: 'center',
        },

        {
            title: '提现金额(￥)',
            dataIndex: 'casheMoney',
            key: 'casheMoney',
            align: 'center',
        },
        {
            title: '应打款金额(￥)',
            dataIndex: 'availableMoney',
            key: 'availableMoney',
            align: 'center',
        },
        {
            title: '手续费(￥)',
            dataIndex: 'casheCharge',
            key: 'casheCharge',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (_, record) => {
                if (keyf === 0) {
                    return <Popconfirm
                        title={'确认打' + record.availableMoney + '元'}
                        onConfirm={() => confirmf(record.casheId)}
                        onCancel={cancel}
                        okText="是"
                        cancelText="否"
                    >
                        <span style={{ color: '#cfae9f', cursor: 'pointer' }}>打款</span>
                    </Popconfirm>


                } else {
                    return <span style={{ color: '#CCC', cursor: 'pointer' }}>已打款</span>
                }
            }
        },
    ];
    const columnst = [
        {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            align: 'center',
        },
        {
            title: '提交申请时间',
            dataIndex: 'applyTime',
            key: 'applyTime',
            align: 'center',
        },
        {
            title: '审核时间',
            dataIndex: 'approveTime',
            key: 'approveTime',
            align: 'center',
        },
        {
            title: '银行卡号',
            dataIndex: 'cardNum',
            key: 'cardNum',
            align: 'center',
        },
        {
            title: '银行名称',
            dataIndex: 'bankName',
            key: 'bankName',
            align: 'center',
        },
        {
            title: '持卡人姓名',
            dataIndex: 'cardUserName',
            key: 'cardUserName',
            align: 'center',
        },
        {
            title: '开户行地址',
            dataIndex: 'bankAddress',
            key: 'bankAddress',
            align: 'center',
        },
        {
            title: '提现金额(￥)',
            dataIndex: 'casheMoney',
            key: 'casheMoney',
            align: 'center',
        },
        {
            title: '应打款金额(￥)',
            dataIndex: 'availableMoney',
            key: 'availableMoney',
            align: 'center',
        },
        {
            title: '手续费(￥)',
            dataIndex: 'casheCharge',
            key: 'casheCharge',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (_, record) => {
                if (keyt === 0) {
                    return <Popconfirm
                        title={'确认打' + record.availableMoney + '元'}
                        onConfirm={() => confirmt(record.casheId)}
                        onCancel={cancel}
                        okText="是"
                        cancelText="否"
                    >
                        <span style={{ color: '#cfae9f', cursor: 'pointer' }}>打款</span>
                    </Popconfirm>


                } else {
                    return <span style={{ color: '#CCC', cursor: 'pointer' }}>已打款</span>
                }
            }
        },
    ];
    useEffect(() => {
        getData()
    }, [key])
    useEffect(() => {
        getDataf()
    }, [keyf])
    useEffect(() => {
        getDatat()
    }, [keyt])
    // const handleOk = () => {
    //     form.submit()
    // };
    const confirmt = (id) => {
        util.httpRequest({
            url: util.API + `/admin/examineExtend`,
            method: 'POST',
            data: {
                id
            }
        }).then((res) => {
            if (res.code === 200) {
                message.success('操作成功')
                getDatat()
            }
        }).catch((err) => {

        });
    }
    const confirmf = (id) => {
        util.httpRequest({
            url: util.API + `/admin/examineAgent`,
            method: 'POST',
            data: {
                id
            }
        }).then((res) => {
            if (res.code === 200) {
                message.success('操作成功')
                getDataf()
            }
        }).catch((err) => {

        });
    }
    const confirm = (id) => {
        util.httpRequest({
            url: util.API + `/admin/examine`,
            method: 'POST',
            data: {
                id
            }
        }).then((res) => {
            if (res.code === 200) {
                message.success('操作成功')
                getData()
            }
        }).catch((err) => {

        });
    }
    const cancel = () => { }
    const getData = () => {
        setloading(true)
        util.httpRequest({
            url: util.API + `/admin/artificerCasheRecord`,
            method: 'POST',
            data: {
                status: key
            }
        }).then((res) => {
            if (res.code === 200) {
                setData(res.data)
                setloading(false)
            }
        }).catch((err) => {

        });
    }
    const getDataf = () => {
        setloading(true)
        util.httpRequest({
            url: util.API + `/admin/agentCasheRecord`,
            method: 'POST',
            data: {
                status: keyf
            }
        }).then((res) => {
            if (res.code === 200) {
                setDataf(res.data)
                setloading(false)
            }
        }).catch((err) => {

        });
    }
    const getDatat = () => {
        setloading(true)
        util.httpRequest({
            url: util.API + `/admin/extendCasheRecord`,
            method: 'POST',
            data: {
                status: keyt
            }
        }).then((res) => {
            if (res.code === 200) {
                setDatat(res.data)
                setloading(false)
            }
        }).catch((err) => {

        });
    }

    const onChangeSelect = (value) => {
        setKey(value)
    }
    const onChangeSelectf = (value) => {
        setKeyf(value)
    }
    const onChangeSelectt = (value) => {
        setKeyt(value)
    }
    const onChange = (key) => {
        if (key == 1) {
            getData()
        } else if (key == 2) {
            getDataf()
        } else {
            getDatat()
        }
    };
    return (
        <div className={styles.home}>
            <Menus select={'cashrecord'} />
            <div className={styles.content}>
                <Tabs onChange={onChange}>
                    <Tabs.TabPane tab="理疗师提现记录" key={1}>
                        <div className={styles.select}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="status"
                                        label="打款状态"
                                    >
                                        <Select defaultValue={0} onChange={onChangeSelect}>
                                            <Select.Option value={0}>未打款</Select.Option>
                                            <Select.Option value={1}>已打款</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            dataSource={data}
                            columns={columns}
                            pagination={false}
                            loading={loading}
                            rowKey={record => record.id}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="店铺提现记录" key={2}>
                        <div className={styles.select}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="status"
                                        label="打款状态"
                                    >
                                        <Select defaultValue={0} onChange={onChangeSelectf}>
                                            <Select.Option value={0}>未打款</Select.Option>
                                            <Select.Option value={1}>已打款</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            dataSource={dataf}
                            columns={columnsf}
                            pagination={false}
                            loading={loading}
                            rowKey={record => record.id}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="推广代理提现记录" key={3}>
                        <div className={styles.select}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="status"
                                        label="打款状态"
                                    >
                                        <Select defaultValue={0} onChange={onChangeSelectt}>
                                            <Select.Option value={0}>未打款</Select.Option>
                                            <Select.Option value={1}>已打款</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Table
                            dataSource={datat}
                            columns={columnst}
                            pagination={false}
                            loading={loading}
                            rowKey={record => record.id}
                        />
                    </Tabs.TabPane>
                </Tabs>

            </div>
            <Record />
        </div>
    )
}

export default Index