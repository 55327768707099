/*
 * @Author: zhangwangwang 
 * @Date: 2021-06-04 14:40:34 
 * @Last Modified by: zhangwangwang
 * @Last Modified time: 2024-07-04 11:04:49
 */
import React, { useEffect, useState } from 'react';
import styles from './index.less';

function Index(props) {

    useEffect(() => {

    }, [])


    return (
        <div className={styles.record}>
            <a href=" " target="_blank">备案号</a >
            <a href="https://beian.miit.gov.cn/" target="_blank">晋ICP备2023022526号-1</a >
        </div >
    )

}
export default Index