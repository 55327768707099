import React from "react";
import "./index.css";
const loadingComponent = () => {
  return (
    <div className='loadable'>
      <img
        src={require("../../images/loading.gif")}
        width="260px"
        height="260px"
      ></img>
    </div>
  );
};
export default loadingComponent;
