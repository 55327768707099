
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Button, DatePicker, Modal, Table, message, Image } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import styles from './index.less'
import util from '../../util/util';
import Menus from '@/component/menu'
import moment from 'moment';
import Record from '@/component/record'
const { RangePicker } = DatePicker;
const Index = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [number, setnumber] = useState(0);
    const [analysisData, setanalysisData] = useState('');
    const dateFormat = 'YYYY-MM-DD';
    const [queryStartDate, setqueryStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [queryEndDate, setqueryEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setloading] = useState();
    const [analysisOrderLists, setanalysisOrderList] = useState([]);
    useEffect(() => {
        analysis()
        analysisOrderList()
    }, [])
    const getUserInfo = (key) => {
        util.httpRequest({
            url: util.API + `/extend/extendInfo`,
            method: 'POST',

        }).then((res) => {
            if (res.code === 200) {
                if (res.data.extendCard) {
                    handleOk()
                } else {
                    message.warning('请先去个人中心绑定银行卡')
                }
            }
        }).catch((err) => {

        });

    }
    const handleOk = () => {
        if (number > 0) {
            util.httpRequest({
                url: util.API + `/extend/withdraw`,
                method: 'POST',
                data: {
                    withdrawMoney: number
                }
            }).then((res) => {
                if (res.code === 200) {
                    setIsModalOpen(false);
                    analysis()
                    analysisOrderList()
                } else {
                    message.error(res.message)
                }

            }).catch((err) => {

            });
        } else {
            message.warning('提现金额不能为0')
        }
    };
    const analysis = (time1, time2) => {
        util.httpRequest({
            url: util.API + `/extend/analysis`,
            method: 'POST',
            data: {
                queryStartDate: time1 ? time1 : queryStartDate,
                queryEndDate: time2 ? time2 : queryEndDate
            }
        }).then((res) => {
            if (res.code === 200) {
                setanalysisData(res.data)
                setnumber(res.data.balance)
            } else {
                message.error(res.message)
            }

        }).catch((err) => {

        });
    }
    const analysisOrderList = (time1, time2) => {
        setloading(true);
        util.httpRequest({
            url: util.API + `/extend/analysisOrderList`,
            method: 'POST',
            data: {
                queryStartDate: time1 ? time1 : queryStartDate,
                queryEndDate: time2 ? time2 : queryEndDate
            }
        }).then((res) => {
            if (res.code === 200) {
                setanalysisOrderList(res.data)
                setloading(false);
            } else {
                message.error(res.message)
                setloading(false);
            }

        }).catch((err) => {

        });
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const withdraw = () => {
        setIsModalOpen(true)
    }
    const onChangeNunber = (e) => {
        setnumber(e.target.value)
    };
    const onChangeTime = (date) => {
        analysis(moment(date[0]._d).format('YYYY-MM-DD'), moment(date[1]._d).format('YYYY-MM-DD'))
        analysisOrderList(moment(date[0]._d).format('YYYY-MM-DD'), moment(date[1]._d).format('YYYY-MM-DD'))
    }
    const columnsf = [
        {
            title: '序号',
            dataIndex: 'detailId',
            key: 'detailId',
            align: 'center',
        },
        {
            title: '推广利润(￥)',
            dataIndex: 'extendProfit',
            key: 'extendProfit',
            align: 'center',
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            align: 'center',
        },
        {
            title: '用户ID',
            dataIndex: 'userId',
            key: 'userId',
            align: 'center',
        },
        {
            title: '技师昵称',
            dataIndex: 'artificerName',
            key: 'artificerName',
            align: 'center',
        },
        {
            title: '技师ID',
            dataIndex: 'artificerId',
            key: 'artificerId',
            align: 'center',
        },
        {
            title: '订单时间',
            dataIndex: 'orderTime',
            key: 'orderTime',
            align: 'center',
        },
        {
            title: '服务名称',
            dataIndex: 'serviceName',
            key: 'serviceName',
            align: 'center',
        },

        {
            title: '服务金额(￥)',
            dataIndex: 'serviceMoeny',
            key: 'serviceMoeny',
            align: 'center',
        },
        {
            title: '服务图片',
            dataIndex: 'serviceImage',
            key: 'serviceImage',
            align: 'center',
            render: (_, record) => (
                <Image
                    width={200}
                    height={50}
                    src={record.serviceImage}
                />
            ),
        },


    ];
    return (
        <div className={styles.home}>
            <Menus select={'profit'} />
            <div className={styles.content}>
                <div className={styles.top}>
                    <Row gutter={16}>
                        <Col span={6}>
                            总推广用户数：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}> {analysisData?.totalExtendUserNum}</span>
                        </Col>
                        <Col span={6}>
                            可提现金额：
                            <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}> {analysisData?.balance}</span>
                        </Col>
                        <Col span={6}>
                            <Button disabled={number > 0 ? false : true} type="primary" onClick={withdraw}>提现</Button>
                        </Col>
                    </Row>

                </div>
                <div className={styles.top}>
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        initialValues={{
                            'datte': [moment(new Date(), dateFormat), moment(new Date(), dateFormat)],
                        }}
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="datte"
                                    label="订单时间"
                                >
                                    <RangePicker onChange={onChangeTime} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                订单数：
                                <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}> {analysisData?.todayOrderNum}</span>
                            </Col>
                            <Col span={6}>
                                纯利润：
                                <span style={{ color: '#cfae9f', fontSize: '1.5rem' }}> {analysisData?.todayProfit}</span>
                            </Col>

                        </Row>

                    </Form>
                </div>
                <div className={styles.table}>
                    <Table
                        dataSource={analysisOrderLists}
                        columns={columnsf}
                        pagination={false}
                        loading={loading}
                        scroll={{
                            y: '43rem',
                        }}
                        rowKey={record => record.detailId}
                    />
                </div>
            </div>
            <Modal
                title="提现"
                visible={isModalOpen}
                onOk={getUserInfo}
                onCancel={handleCancel}
                centered
                destroyOnClose
            >
                <Input defaultValue={analysisData?.balance} onChange={onChangeNunber} type='number' prefix="￥" suffix="RMB" />
            </Modal>
            <Record />
        </div>
    )
}

export default Index